import React from 'react';
import { graphql, Link } from 'gatsby';
import Helmet from 'react-helmet';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Call from '../components/Call';

const Home = (props) => {
  const intro = props.data.intro;
  const site = props.data.site.siteMetadata;
  const services = props.data.services.edges;
  const features = props.data.features.edges;
  const introImageClasses = `intro-image ${intro.frontmatter.intro_image_absolute && 'intro-image-absolute'} ${
    intro.frontmatter.intro_image_hide_on_mobile && 'intro-image-hide-mobile'
  }`;

  return (
    <Layout bodyClass='page-home'>
      <SEO title={site.title} />
      <Helmet>
        <meta
          name='description'
          content='Small Business Theme. Multiple content types using Markdown and JSON sources. Responsive design and SCSS. This is a beautiful and artfully designed starting theme.'
        />
      </Helmet>

      <div className='intro'>
        <div className='container'>
          <div className='row justify-content-start'>
            <div className='col-12 col-md-12 col-lg-12 order-1'>
              <div dangerouslySetInnerHTML={{ __html: intro.html }} />
              <p className='py-2' style={{ borderTop: '1px solid black' }}>
                Sara Beth Bonacci DDS, Jourdan H. Freed DDS, David M. Caryl DDS, and Joseph R. Bonacci DDS (emeritus)
              </p>
              <div className='col-12 col-md-6 px-0'>
                <Call showButton />
              </div>
              <div className='pt-4'>
                <p>
                  When you visit our office, <strong>your smile and comfort are our top priorities.</strong> Our entire
                  team is dedicated to providing you with the personalized, gentle care that you deserve. At Syracuse
                  Dental Associates, we offer patients a full-service business office to help with insurance claims and
                  a large clinical staff that is ready and willing to answer all your questions and concerns. We are
                  also a handicap-accessible office, and have an on-staff dentist who speaks Spanish and French.
                </p>

                <p>
                  Part of our commitment to serving our patients includes providing information that helps them to make
                  more informed decisions about their oral health needs. This website is a resource we hope you’ll find
                  both useful and interesting.
                </p>

                <p>
                  Please browse through our website and be sure to give us a call if you have any questions or would
                  like to schedule an appointment.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* {services.length > 0 && (
        <div className='strip'>
          <div className='container pt-6 pb-6 pb-md-10'>
            <div className='row justify-content-start'>
              {services.map(({ node }) => (
                <div key={node.id} className='col-12 col-md-4 mb-1'>
                  <div className='service service-summary'>
                    <div className='service-content'>
                      <h2 className='service-title'>
                        <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
                      </h2>
                      <p>{node.excerpt}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className='row justify-content-center'>
              <div className='col-auto'>
                <Link className='button button-primary' to='/services/'>
                  View All Services
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}

      {features.length > 0 && (
        <div className='strip strip-grey'>
          <div className='container pt-6 pb-6 pt-md-10 pb-md-10'>
            <div className='row justify-content-center'>
              {features.map(({ node }) => (
                <div key={node.id} className='col-12 col-md-6 col-lg-4 mb-2'>
                  <div className='feature'>
                    {node.image && (
                      <div className='feature-image'>
                        <img src={node.image} />
                      </div>
                    )}
                    <h2 className='feature-title'>{node.title}</h2>
                    <div className='feature-content'>{node.description}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )} */}
    </Layout>
  );
};

export const query = graphql`
  query {
    services: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/services/.*/" } }
      sort: { fields: [frontmatter___weight], order: ASC }
      limit: 6
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM YYYY")
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
    intro: markdownRemark(fileAbsolutePath: { regex: "/content/index.md/" }) {
      html
      frontmatter {
        image
        intro_image
        intro_image_absolute
        intro_image_hide_on_mobile
        title
      }
    }
    features: allFeaturesJson {
      edges {
        node {
          id
          title
          description
          image
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export default Home;
